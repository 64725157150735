import React, { useState, useEffect } from 'react';
import { HomePage } from './page/homePage';
import { useSelector, useDispatch } from 'react-redux';
import { selectToken, getToken } from './store/agreementToken';
import * as _api from './api/authApi';
import {
  selectStatus as selectInitialStatus,
  updateStatus,
} from './store/initialStatusSlice';
import { Spinner } from './components/loader/loaders';
import { UpgradeMessage } from './components/upgradeMessage/upgradeMessage';
import { ErrorMessage } from './components/errorMessage/errorMessage';
import './App.css';
import { configure, ActivityIndicator } from '@revisohq/react-components';
import { format } from '@revisohq/js-libraries';

const formatter = new format.Date('dd/mm/yyyy');
const amountFormatter = new format.Amount({
  groupingSeparator: '.',
  decimalSeparator: ',',
  minimumDecimals: 2,
  maximumDecimals: 2,
});

configure({
  amountInput: {
    format: amountFormatter.format,
    parse: amountFormatter.parse,
    roundValue: (x) => Number(x.toFixed(2)),
    characterOnNumpadDecimal: ',',
    allowCalculation: false,
  },
  datePicker: {
    format: formatter.format,
    parse: formatter.toJSON,
    l10n: {
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      days: ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'],
      openPaneButtonText: 'Select date',
      nextMonthButtonText: 'Next',
      previousMonthButtonText: 'Previous',
    },
  },
  dropdown: {
    noSearchResultsText: 'No matching results',
  },
  popup: {
    // `window` is the global window instance
    rootWindow: window,
  },
  listButtons: {
    stickyAreaButtonActiveText: 'Unlock button area',
    stickyAreaButtonInactiveText: 'Lock button area',
    columnCountActiveText: 'Show more columns',
    columnCountInactiveText: 'Show fewer columns',
  },
  paginationView: {
    getTextForCurrentPage: (data) => {
      if (data == null) {
        return <ActivityIndicator type="dots" />;
      }
      const { fromIndex, toIndex, totalItems } = data;
      if (totalItems === 0) {
        return 'No items';
      }
      return `Showing ${fromIndex}-${toIndex} of ${totalItems} items`;
    },
    firstPageButtonTitle: 'First page',
    lastPageButtonTitle: 'Last page',
    nextPageButtonTitle: 'Next page',
    previousPageButtonTitle: 'Previous page',
  },
  searchInput: {
    buttonText: 'Search',
  },
  // The tagsWidget has optional settings not included here
  tagsWidget: {
    duplicateEntryWarning: 'Tag already added',
    maxLengthWarning: 'Tags cannot be longer than [MAXLENGTH] characters.',
  },
});

function App() {
  const token = useSelector(selectToken);
  const status = useSelector(selectInitialStatus);

  const dispatch = useDispatch();

  const [loadState, setLoadState] = useState('LOADING');

  useEffect(() => {
    (async function _() {
      try {
        if (!token) {
          dispatch(getToken());
        } else {
          await getStatus();
        }
      } catch (error) {
        console.error(error);
        setLoadState('ERROR');
      }
    })();
  }, [token]);

  return (
    <>
      {loadState === 'LOADING' && <Spinner />}
      {loadState === 'ERROR' && <ErrorMessage />}
      {loadState === 'DONE' &&
        (status?.isFeatureEnabled ? token && <HomePage /> : <UpgradeMessage />)}
    </>
  );

  async function getStatus() {
    const status = await _api.GetInitialStatus();
    if (!status || status.error) {
      setLoadState('ERROR');
    } else {
      dispatch(updateStatus(status));
      setLoadState('DONE');
    }
  }
}
export default App;
