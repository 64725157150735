import { configureStore } from '@reduxjs/toolkit'
import agreementReducer from './store/agreementToken'
import initialStatusReducer from './store/initialStatusSlice'

export default configureStore({
  reducer: {
    agreement: agreementReducer,
    initialStatus: initialStatusReducer
  },
})
