
export const COMPANY_OK = "COMPANY_OK";
export const EMAIL_SENT = "EMAIL_SENT";
export const SMS_SENT = "SMS_SENT";
export const UNAUTHORIZED = "UNAUTHORIZED";
export const WRONG_PIN = "WRONG_PIN";
export const AGYO_ERROR = "AGYO_ERROR";
export const UNPROCESSABLE_ENTITY = "UNPROCESSABLE_ENTITY";
export const SMS_NOT_SENT = "SMS_NOT_SENT";
export const SMS_ALREADY_SENT = "SMS_ALREADY_SENT";
export const AGYO_COMPANY_ALREADY_EXISTS = "AGYO_COMPANY_ALREADY_EXISTS";
export const COMPANY_IN_ACTIVATION = "COMPANY_IN_ACTIVATION";