import { SplitView } from '@revisohq/react-components';
import React from 'react';
import texts from '../../utils/texts';

const CompanyInfo = (props) => {
  const { companyData } = props;

  return (
    <React.Fragment>
      <h2>{texts('COMPANY')}</h2>
      <div className="box mw-50p mh-400">
        <SplitView className="mw-400 mb-20">
          <SplitView.Left>
            <div className="fw-b">{texts('BUSINESS_NAME')}</div>
            <div>{companyData.businessName}</div>
          </SplitView.Left>
          <SplitView.Right></SplitView.Right>
        </SplitView>
        <SplitView className="mw-400 mb-20">
          <SplitView.Left>
            <div className="fw-b">{texts('FISCAL_CODE')}</div>
            <div>{companyData.fiscalCode}</div>
          </SplitView.Left>
          <SplitView.Right>
            <div className="fw-b">{texts('VAT_NUMBER')}</div>
            <div>{companyData.vat_number}</div>
          </SplitView.Right>
        </SplitView>
        <SplitView className="mw-400 mb-20">
          <SplitView.Left>
            <div className="fw-b">{texts('CONTACT_EMAIL')}</div>
            <div>{companyData.contactEmail}</div>
          </SplitView.Left>
          <SplitView.Right></SplitView.Right>
        </SplitView>
        <SplitView className="mw-400 mb-20">
          <SplitView.Left>
            <div className="fw-b">{texts('ADDRESS')}</div>
            <div>{companyData.address}</div>
          </SplitView.Left>
          <SplitView.Right></SplitView.Right>
        </SplitView>
        <SplitView className="mw-400 mb-20">
          <SplitView.Left>
            <div className="fw-b">{texts('ZIP_CODE')}</div>
            <div>{companyData.zip_code}</div>
          </SplitView.Left>
          <SplitView.Right>
            <div className="fw-b">{texts('LOCATION_CITY')}</div>
            <div>
              {companyData.location} - {companyData.city}
            </div>
          </SplitView.Right>
        </SplitView>
      </div>
    </React.Fragment>
  );
};

export default CompanyInfo;
