import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'initialStatus',
  initialState: {
    status: null,
  },
  reducers: {
    updateStatus: (state, action) => {
      state.status = action.payload
    }
  }
});

export const selectStatus = state => state.initialStatus.status
export const { updateStatus } = slice.actions

export default slice.reducer