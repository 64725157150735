import { getBaseUrl } from './baseUrl';

export async function GetTokenAsync() {
  try {
    const hrefParts = window.location.href.split('?');
    let eat = null;

    if (hrefParts.length === 2) {
      eat = new URLSearchParams(hrefParts[1]).get('embeddedAppToken');
      console.log(`eat: ${eat}`);
    }

    var res = await fetch(`${getBaseUrl()}api/grant/${eat}`, {
      method: 'GET',
    });

    return await res.json();
  } catch (error) {
    return console.log(error);
  }
}
