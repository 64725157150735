import { createSlice } from '@reduxjs/toolkit'
import token from "./sharedToken"
import * as _api from "../api/tokenApi"

export const slice = createSlice({
  name: 'agreement',
  initialState: {
    token: null,
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload
    }
  }
})

export function getToken() {
  return async function getTokenThunk(dispatch, getState) {
    try {
      var res = await _api.GetTokenAsync()
      token.value = res
      dispatch(slice.actions.setToken(res))
    } catch {
      console.log("Error")
      dispatch(slice.actions.setToken("Error"))
    }
  }
}

export const selectToken = state => state.agreement.token
export const { setToken: initialize } = slice.actions

export default slice.reducer
