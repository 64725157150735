import token from '../store/sharedToken';
import axios from 'axios';
import { getBaseUrl } from './baseUrl';

export async function GetInitialStatus() {
  try {
    const result = await axios.get(`${getBaseUrl()}api/authentication`, {
      headers: getHeader('GET'),
    });
    return result.data;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function createUser(form) {
  try {
    const result = await axios.post(
      `${getBaseUrl()}api/authentication/create-user`,
      JSON.stringify(form),
      {
        headers: getHeader('POST'),
      },
    );
    return result.data;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function GetRegistrationData() {
  try {
    const res = await axios.get(
      `${getBaseUrl()}api/authentication/get-registration-data`,
      {
        headers: getHeader('GET'),
      },
    );
    return res.data;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function CreateCompany(data) {
  try {
    const res = await axios.post(
      `${getBaseUrl()}api/authentication/create-company`,
      JSON.stringify(data),
      {
        headers: getHeader('POST'),
      },
    );
    return res.data;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function getDigitalEndpoint() {
  try {
    const res = await axios.get(
      `${getBaseUrl()}api/authentication/digital-endpoint`,
      {
        headers: getHeader('GET'),
      },
    );
    return res.data;
  } catch (error) {
    console.error(error);
    return false;
  }
}

const getHeader = (method) => {
  switch (method) {
    case 'POST':
      return {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'x-reviso-revisoitbalance-grant-token': token.value,
      };
    default:
      return {
        'x-reviso-revisoitbalance-grant-token': token.value,
      };
  }
};
