import React, { useState } from "react";
import { ListRow, ListCell, DownloadFileIcon, SyncAltIcon, TimesIcon, CheckIcon, TrashAltIcon, Popup, Form, FormButton } from "@revisohq/react-components";
import {
  getFormattedDate,
  reportTableConfig as columnConfig,
  getLocalDateTimeString
} from "../../utils/common";
import * as _api from "../../api/balanceApi"
import texts from '../../utils/texts'
import { balanceTypes } from '../../utils/common'
import download from "downloadjs"

const ReportTableItem = (props) => {

  const { item, onItemUpdated, onListRefresh } = props;

  const [showDeletePopup, setDeletePopup] = useState(false);

  const getStatus = (status) => {
    switch (status) {
      case 0:
        return "NUOVO"
      case 1:
        return "ESTRAZIONE IN CORSO"
      case 2:
        return <span style={{ color: 'darkgreen' }}>INVIATO</span>
      case 3:
        return "PRONTO PER L'INVIO"
      case 4:
        return <span style={{ color: 'darkred' }}>ERRORE DATI</span>
      case 5:
        return <span style={{ color: 'darkred' }}>ERRORE INVIO</span>
      default:
        return "SCONOSCIUTO"
    }
  }

  const downloadCsvFile = async (reportId, filename) => {
    var file = await _api.downloadCsvFile(reportId);
    if (file) {
      download(file, filename + ".csv", "text/csv")
    }
  }

  const deleteReport = async (reportId) => {
    var ok = await _api.deleteReport(reportId);
    setDeletePopup(false);
    onListRefresh();
  }

  const refreshReportStatus = async (reportId) => {
    var report = await _api.getReport(reportId)
    if (report) {
      onItemUpdated(report)
    }
  }

  const resend = async (reportId) => {
    var report = await _api.resendReport(reportId)
    if (report) {
      onItemUpdated(report)
    }
  }

  const getBalanceTypeString = (type) => {
    const balanceType = balanceTypes.find((x) => {
      return x.id === type
    })
    return balanceType.text ?? "Ordinario"
  }

  return <><ListRow style={{ height: "20px !important" }}>
    <ListCell disabled {...columnConfig.sendDate}>
      <div>{getFormattedDate(item.sendDate)}</div>
    </ListCell>
    <ListCell disabled {...columnConfig.creationDate}>
      <div>{getLocalDateTimeString(item.creationDate)}</div>
    </ListCell>
    <ListCell disabled {...columnConfig.status}>
      <div>
        <b>{getStatus(item.status)}</b>
      </div>
    </ListCell>
    <ListCell disabled {...columnConfig.type}>
      <div>{getBalanceTypeString(item.type)}</div>
    </ListCell>
    <ListCell disabled {...columnConfig.name}>
      <div>{item.description}</div>
    </ListCell>
    <ListCell disabled {...columnConfig.periodStart}>
      <div>{getFormattedDate(item.start)}</div>
    </ListCell>
    <ListCell disabled {...columnConfig.periodEnd}>
      <div>{getFormattedDate(item.end)}</div>
    </ListCell>
    <ListCell disabled {...columnConfig.includeDraftEntries}>
      <div>
        {item.includeDraftEntries ? <CheckIcon /> : <TimesIcon />}
      </div>
    </ListCell>
    <ListCell className="ta-r" disabled {...columnConfig.updateStatusButton}>
      <div title={texts("UPDATE_REPORT_STATUS")}
        onClick={() => refreshReportStatus(item.id)}>
        <SyncAltIcon className="clickable" />
      </div>
    </ListCell>
    <ListCell className="ta-r" disabled {...columnConfig.downloadButton}>
      {(item.status === 2 || item.status === 3 || item.status === 5) &&
        <div title={texts("DOWNLOAD_CSV")}
          onClick={() => downloadCsvFile(item.id, item.description)}>
          <DownloadFileIcon className="clickable" />
        </div>}
    </ListCell>
    <ListCell className="ta-r" disabled {...columnConfig.deleteButton}>
      <div title={texts("DELETE")}
        onClick={() => setDeletePopup(true)}>
        <TrashAltIcon className="clickable" />
      </div>
    </ListCell>
  </ListRow>
    {showDeletePopup &&
      <Popup modal style={{ position: "fixed", top: "25%" }} onClose={() => { setDeletePopup(false) }}>
        <Form onSubmit={(event) => event.preventDefault()}>
          <h3 style={{ padding: '0px 20px' }}>{texts('ARE_YOU_SURE_DELETE').replace('[NAME]', item.description)}</h3>
          <FormButton
            text={texts('NO')}
            type="secondary"
            onClick={() => setDeletePopup(false)}
          />
          <FormButton
            text={texts('YES')}
            type="primary"
            onClick={() => deleteReport(item.id)}
          />
        </Form>
      </Popup>
    }
  </>;
}

export default ReportTableItem;