import { StackView, Button, Form, NotificationBanner, FormControl, Popup, TextInput } from "@revisohq/react-components"
import { FormButton, FormColumn } from "@revisohq/react-components/form"
import React, { useEffect, useState } from "react"
import * as _api from "../../api/authApi"
import { isNullOrWhitespace, validatePassword } from "../../utils/common"
import texts from '../../utils/texts'

export function NewUserLink(props) {

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isCreateBtnDisabled, setIsCreateBtnDisabled] = useState(false)

  const [form, setForm] = useState({})

  const [errorBanner, setErrorBanner] = useState(false)
  const [errorText, setErrorText] = useState()

  const [successBanner, setSuccessBanner] = useState(false)

  const [digitalEndpoint, setDigitalEndpoint] = useState('https://app.teamsystemdigital.com/portale/');

  const openModal = () => {
    setErrorText(null)
    setErrorBanner(false)
    setSuccessBanner(false)
    setIsCreateBtnDisabled(false)
    setForm({})
    setIsModalVisible(true)
  }

  const createUser = () => {
    setErrorBanner(false)
    setSuccessBanner(false)
    if (validateForm()) {
      setIsCreateBtnDisabled(true)
      _api.createUser(form).then(res => {
        setIsCreateBtnDisabled(false)
        if (res) {
          setForm({})
          setSuccessBanner(true)
        } else {
          setErrorText("Creazione dell'utente fallita. Riprovare più tardi")
          setErrorBanner(true)
        }
      }).catch(err => {
        setErrorText("Creazione dell'utente fallita. Riprovare più tardi")
        setErrorBanner(true)
        setIsCreateBtnDisabled(false)
        console.error(err)
      })
    }
  }

  const validateForm = () => {
    setErrorBanner(false)
    if (!isNullOrWhitespace(form.password) && form.password !== form.confirmPassword) {
      setErrorText("Password e conferma non coincidono")
      setErrorBanner(true)
    } else if (!validatePassword(form.password)) {
      setErrorText("La password deve avere almeno 8 caratteri, un carattere maiuscolo e almeno un numero.")
      setErrorBanner(true)
    } else if (
      isNullOrWhitespace(form.lastName) ||
      isNullOrWhitespace(form.firstName) ||
      isNullOrWhitespace(form.email) ||
      isNullOrWhitespace(form.password)
    ) {
      setErrorText("Tutti i campi sono obbligatori")
      setErrorBanner(true)
    } else {
      return true
    }
    return false
  }

  return <> {isModalVisible &&
    <Popup modal style={{ position: "fixed", top: "25%" }} onClose={() => {
      setForm({})
      setIsModalVisible(false)
    }}>
      <Form title={texts("CREATE_TS_USER")} onSubmit={(event) => event.preventDefault()}>
        <StackView direction="horizontal" className="mt-10">
          <FormColumn style={{ borderRight: 'none', width: '100%' }}>
            <FormControl text="Cognome" mandatory>
              <TextInput value={form.lastName} onChangeValue={text => setForm({ ...form, lastName: text })} />
            </FormControl>
          </FormColumn>
          <FormColumn style={{ width: '100%' }}>
            <FormControl text="Nome" mandatory>
              <TextInput value={form.firstName} onChangeValue={text => setForm({ ...form, firstName: text })} />
            </FormControl>
          </FormColumn>
        </StackView>
        <FormColumn style={{ width: '100%' }}>
          <FormControl text="Email" mandatory>
            <TextInput value={form.email} onChangeValue={text => setForm({ ...form, email: text })} />
          </FormControl>
        </FormColumn>
        <StackView direction="horizontal">
          <FormColumn style={{ borderRight: 'none', width: '100%' }}>
            <FormControl text="Password" mandatory>
              <TextInput type="password" value={form.password}
                onChangeValue={text => setForm({ ...form, password: text })} />
            </FormControl>
          </FormColumn>
          <FormColumn style={{ width: '100%' }}>
            <FormControl text="Conferma la password" mandatory>
              <TextInput type="password" value={form.confirmPassword}
                onChangeValue={text => setForm({ ...form, confirmPassword: text })} />
            </FormControl>
          </FormColumn>
        </StackView>
        <StackView direction="horizontal" style={{ padding: '10px' }}>
          <b>Attenzione: per accedere a TS Digital usa il tuo TSID, al primo accesso con TSID dovrai completare l'associazione con l'utenza TS Digital appena creata</b>
        </StackView>
        {errorBanner && <div className="ml-15 mr-15">
          <NotificationBanner style={{ paddingRight: '10px', marginBottom: '10px', marginTop: '10px' }}
            type="error"
            text={errorText}
            isInline
            onClose={() => setErrorBanner(false)}
          />
        </div>}
        {successBanner && <div className="ml-15 mr-15">
          <NotificationBanner style={{ paddingRight: '10px', marginBottom: '10px', marginTop: '10px' }}
            type="success"
            isInline
            text={"L'utenza è stata creata con successo. Puoi crearne un'altra, oppure chiudere questo form."}
            onClose={() => setSuccessBanner(false)}
          />
        </div>}
        <FormButton
          text="Crea"
          type="primary"
          disabled={isCreateBtnDisabled}
          onClick={() => createUser()}
        />
      </Form>
    </Popup>
  }
    <a href="#" onClick={() => openModal()}>crealo da qui adesso</a>
  </>
}