import token from '../store/sharedToken';
import axios from 'axios';
import { getBaseUrl } from './baseUrl';

export async function downloadCsvFile(reportId) {
  try {
    let result = await axios.get(
      `${getBaseUrl()}api/balance/download-csv?reportId=${reportId}`,
      {
        headers: getHeader('GET'),
      },
    );
    return result.data;
  } catch (error) {
    return false;
  }
}

export async function createReport(input) {
  try {
    let result = await axios.post(`${getBaseUrl()}api/balance/reports`, input, {
      headers: getHeader('POST'),
    });
    return result.data;
  } catch (error) {
    return false;
  }
}

export async function resendReport(reportId) {
  try {
    let result = await axios.get(
      `${getBaseUrl()}api/balance/reports/resend/${reportId}`,
      {
        headers: getHeader('GET'),
      },
    );
    return result.data;
  } catch (error) {
    return false;
  }
}

export async function getReport(reportId) {
  try {
    let result = await axios.get(
      `${getBaseUrl()}api/balance/reports/${reportId}`,
      {
        headers: getHeader('GET'),
      },
    );
    return result.data;
  } catch (error) {
    return false;
  }
}

export async function deleteReport(reportId) {
  try {
    let result = await axios.delete(
      `${getBaseUrl()}api/balance/reports/${reportId}`,
      {
        headers: getHeader('DELETE'),
      },
    );
    return result.data;
  } catch (error) {
    return false;
  }
}

export async function getReportsList() {
  try {
    let result = await axios.get(`${getBaseUrl()}api/balance/reports`, {
      headers: getHeader('GET'),
    });
    return result.data;
  } catch (error) {
    return false;
  }
}

const getHeader = (method) => {
  switch (method) {
    case 'POST':
      return {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'x-reviso-revisoitbalance-grant-token': token.value,
      };
    default:
      return {
        'x-reviso-revisoitbalance-grant-token': token.value,
      };
  }
};
