import React from 'react';
import './logo.css';

const Logo = () => {
  return (
    <img id="logo" alt="Bilancio in Cloud" src={require('../../assets/logo.png')} />
  );
};

export default Logo;
