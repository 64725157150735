import React from 'react';
import CompanyInfo from '../company/CompanyInfo';
import ReportTable from '../report/ReportTable';
import { selectStatus } from '../../store/initialStatusSlice';
import { useSelector } from 'react-redux';
import { NewUserSection } from '../newUserSection/newUserSection';

const MainSection = () => {
  const initialStatus = useSelector(selectStatus);

  return (
    <>
      {initialStatus?.isAuthenticated ? (
        <>
          <ReportTable />
          {/* <NewUserSection /> */}
        </>
      ) : (
        <CompanyInfo companyData={initialStatus.companyInfo} />
      )}
    </>
  );
};

export default MainSection;
