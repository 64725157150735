import React from "react"
import "@revisohq/react-components/css/activity-indicator.css"
import { ActivityIndicator } from "@revisohq/react-components"

export function Spinner() {
  return (
    <ActivityIndicator
      type="spinner" style={{ width: '100%', position: 'fixed', top: '40%', zIndex: '99999' }}
    />
  )
}

export function DotsLoader() {
  return (
    <ActivityIndicator
      type="dots" style={{ 'align-self': 'center', 'margin-left': '5px', 'margin-right': '5px' }}
    />
  )
}
