//import { get as getCookie } from "browser-cookies";
import translations from "./translations";

var userLang = navigator.language || navigator.userLanguage;

export function getUserLanguage() {
  return userLang;
}

function getTranslation(textId) {
  const allLanguages = translations.texts[textId];

  if (!allLanguages) {
    return textId;
  }

  if (userLang in allLanguages) {
    return allLanguages[userLang];
  } else {
    return allLanguages["it"];
  }
}

export default (textId, replacements) => {
  const text = getTranslation(textId);
  if (!replacements) {
    return text;
  }

  return text.replace(/\[[^\[\]]+\]/g, (match) => {
    const withoutBrackets = match.slice(1, -1);
    const replacement = replacements[withoutBrackets];
    return replacement == null ? match : replacement;
  });
};
