import React, { useEffect, useState } from 'react';
import texts from '../../utils/texts'
import { FormControl, Form, FormButton, TextInput, SimpleDropdown, StackView, DatePicker, Checkbox, Popup, FormColumn, NotificationBanner } from '@revisohq/react-components';
import * as _api from "../../api/balanceApi";
import { balanceTypes } from "../../utils/common"
import { useDispatch, useSelector } from 'react-redux';
import {
  selectStatus as selectInitialStatus,
  updateStatus,
} from '../../store/initialStatusSlice';

const NewReportSection = (props) => {

  const dispatch = useDispatch();
  const status = useSelector(selectInitialStatus);

  const { onCloseModal, onLoadingChanged, onReportCreated } = props;

  const [formData, setFormData] = useState({
    description: '',
    start: new Date().getFullYear() + '-01-01',
    end: new Date().getFullYear() + '-12-31',
    type: 0,
    includeDraftEntries: false
  });

  const [isFormDataValid, setIsFormDataValid] = useState(true);

  const [isSubmitted, setIsSubmitted] = useState(false);

  const formColumnStyle = { borderRight: 'none', width: '100%' };
  const popupStyle = { position: "fixed", top: "25%", padding: "15px" };

  const validateData = () => {
    const dateFrom = Date.parse(fromITDate(formData.start));
    const dateTo = Date.parse(fromITDate(formData.end));
    if (!formData.description || !formData.start || !formData.end || dateFrom > dateTo) {
      setIsFormDataValid(false);
      return false;
    }
    else {
      setIsFormDataValid(true);
      return true;
    }
  }

  const fromITDate = (date) => {
    const parts = date.split("/");
    return `${parts[2]}-${parts[1]}-${parts[0]}`
  }

  const createNewReport = () => {
    setIsSubmitted(true);
    let isDataValid = validateData();
    if (isDataValid) {
      onLoadingChanged(true);
      _api.createReport({
        description: formData.description,
        start: formData.start,
        end: formData.end,
        type: formData.type,
        includeDraftEntries: formData.includeDraftEntries,
        status: 0
      }).then(async res => {
        onReportCreated(!!res);
        onLoadingChanged(false);
        var reports = await _api.getReportsList();
        dispatch(updateStatus({ ...status, reports }))
      },
        err => {
          console.error(err);
          onLoadingChanged(false);
          onReportCreated(false);
        })
    }
    else {
      setIsFormDataValid(false);
    }
  }

  useEffect(() => {
    if (isSubmitted) {
      validateData();
    }
  }, [formData])

  return (
    <React.Fragment>
      <Popup modal style={popupStyle}>
        <Form title={texts("EXPORT_BALANCE_SHEET_DATA")} onSubmit={(event) => event.preventDefault()}>
          <StackView direction="horizontal">
            <FormColumn style={formColumnStyle}>
              <FormControl className="mt-20" text={texts("DESCRIPTION")} mandatory>
                <TextInput value={formData.description}
                  onChangeValue={text => { setFormData({ ...formData, description: text }) }} />
              </FormControl>
            </FormColumn>
            <FormColumn style={formColumnStyle}>
              <FormControl className="mt-20" text={texts("BALANCE_TYPE")} mandatory>
                <SimpleDropdown values={balanceTypes}
                  value={formData.type}
                  style={{ display: "inline !important;" }}
                  onChangeValue={value => { setFormData({ ...formData, type: value }) }}
                />
              </FormControl>
            </FormColumn>
          </StackView>
          <StackView direction="horizontal">
            <FormColumn style={formColumnStyle}>
              <FormControl text={texts("DATE_FROM") + ' *'}>
                <DatePicker
                  value={formData.start}
                  onChangeValue={date => { setFormData({ ...formData, start: date }) }}
                />
              </FormControl>
            </FormColumn>
            <FormColumn style={formColumnStyle}>
              <FormControl text={texts("DATE_TO") + ' *'}>
                <DatePicker
                  value={formData.end}
                  onChangeValue={date => { setFormData({ ...formData, end: date }) }}
                />
              </FormControl>
            </FormColumn>
            <FormColumn style={formColumnStyle}>
              <FormControl text={texts("INCLUDE_DRAFT_ENTRIES")}>
                <Checkbox checked={formData.includeDraftEntries} onChangeChecked={(isChecked) => { setFormData({ ...formData, includeDraftEntries: isChecked }) }}></Checkbox>
              </FormControl>
            </FormColumn>
          </StackView>
          <FormColumn style={formColumnStyle}>
            {!isFormDataValid && isSubmitted && <NotificationBanner type="error" text={texts("NOT_VALID_DATA")} />}
          </FormColumn>
          <FormButton align="right"
            text={texts("CANCEL")}
            type="secondary"
            onClick={() => { onCloseModal() }}
          />
          <FormButton align="right"
            text={texts("SEND")}
            type="primary"
            onClick={createNewReport}
            disabled={!isFormDataValid}
          />
        </Form>
      </Popup>
    </React.Fragment>
  );
}

export default NewReportSection;