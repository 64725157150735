import './authenticationModal.css';
import {
  StackView,
  Form,
  FormColumn,
  NotificationBanner,
  FormControl,
  Popup,
  TextInput,
  FormButton,
} from '@revisohq/react-components';
import React, { useEffect, useState } from 'react';
import * as _api from '../../api/authApi';
import * as authCodes from './authenticationCodes';
import texts from '../../utils/texts';

const AuthenticationModal = (props) => {
  const { company, isUserRegistered, closeModal } = props;

  const [isWarningVisibile, setIsWarningVisibile] = useState(false);
  const [isErrorVisibile, setIsErrorVisibile] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);

  const [state, setState] = useState('DATA');
  const [pin, setPin] = useState('');

  const [currentCompany, setCurrentCompany] = useState({
    cellphone: company?.phone,
    idNumber: company?.cf,
    vat_number: company?.vat_number,
    email: company?.email,
    name: company?.name,
  });

  const [emailRecipient, setEmailRecipient] = useState(company?.email);

  const [disableFields, setDisableFields] = useState({});

  useEffect(() => {
    setDisableFields({
      name: !!currentCompany?.name,
      email: !!currentCompany?.email,
      idNumber: !!currentCompany?.idNumber,
      vat_number: !!currentCompany?.vat_number,
    });
  }, [isUserRegistered]);

  const isNullOrWhitespace = (input) => {
    if (typeof input === 'undefined' || input == null) return true;
    return input.replace(/\s/g, '').length < 1;
  };

  const sendSms = () => {
    if (
      isNullOrWhitespace(currentCompany.cellphone) ||
      isNullOrWhitespace(currentCompany.idNumber) ||
      isNullOrWhitespace(currentCompany.vat_number) ||
      isNullOrWhitespace(currentCompany.email) ||
      isNullOrWhitespace(currentCompany.name)
    ) {
      setIsWarningVisibile(true);
    } else {
      setIsBtnDisabled(true);
      _api
        .CreateCompany({
          company: getCompanyObject(),
          saveData: true,
        })
        .then(
          (res) => {
            setIsBtnDisabled(false);
            if (
              res?.code === authCodes.SMS_SENT ||
              res?.code === authCodes.SMS_ALREADY_SENT
            ) {
              setState(authCodes.SMS_SENT);
            } else if (res?.code === authCodes.WRONG_PIN) {
              setErrorText(texts('WRONG_PIN'));
              setIsErrorVisibile(true);
            } else if (res?.code === authCodes.AGYO_COMPANY_ALREADY_EXISTS) {
              setErrorText(texts('EXISTING_COMPANY'));
              setIsErrorVisibile(true);
            } else {
              setErrorText(texts('SMS_SENDING_ERROR'));
              setIsErrorVisibile(true);
            }
          },
          (err) => {
            setIsBtnDisabled(false);
            console.error(err);
          },
        );
    }
  };

  const createCompany = () => {
    setIsBtnDisabled(true);
    _api
      .CreateCompany({
        company: getCompanyObject(),
        pin: pin,
      })
      .then((res) => {
        setIsBtnDisabled(false);
        if (res?.code === authCodes.COMPANY_OK) {
          setState(authCodes.COMPANY_OK);
        } else if (res?.code === authCodes.EMAIL_SENT) {
          if (
            Array.isArray(res.description?.recipients) &&
            res.description.recipients.length > 0
          )
            setEmailRecipient(res.description.recipients[0]);
          setState(authCodes.EMAIL_SENT);
        } else if (res?.code === authCodes.WRONG_PIN) {
          setErrorText(texts('WRONG_PIN'));
          setIsErrorVisibile(true);
        } else if (res?.code === authCodes.AGYO_COMPANY_ALREADY_EXISTS) {
          setErrorText(texts('EXISTING_COMPANY'));
          setIsErrorVisibile(true);
        } else if (res?.code === authCodes.COMPANY_IN_ACTIVATION) {
          setErrorText(texts('ALREADY_ACTIVATED_ON_TS_DIGITAL'));
          setIsErrorVisibile(true);
        } else {
          setErrorText(texts('UNEXPECTED_ERROR'));
          setIsErrorVisibile(true);
        }
      });
  };

  const getCompanyObject = () => {
    return {
      cf: currentCompany.idNumber,
      vat_number: currentCompany.vat_number,
      phone: currentCompany.cellphone,
      email: currentCompany.email,
      name: currentCompany.name,
    };
  };

  return (
    <Popup
      modal
      style={{ position: 'fixed', top: '25%' }}
      onClose={() => closeModal(state === authCodes.COMPANY_OK)}
    >
      <div align="center" style={{ margin: '15px' }}>
        <Form
          title={texts('TS_BILANCIO_ACTIVATION')}
          onSubmit={(event) => event.preventDefault()}
        >
          {state === 'DATA' && (
            <StackView
              direction="vertical"
              className="mt-20"
              style={{ width: '450px', alignItems: 'center !important' }}
            >
              <StackView direction="horizontal" className="mt-10">
                <FormColumn style={{ borderRight: 'none', width: '100%' }}>
                  <FormControl
                    text={texts('BUSINESS_NAME')}
                    className="inputText"
                    mandatory
                  >
                    <TextInput
                      value={currentCompany.name}
                      disabled={disableFields.name}
                      onChangeValue={(text) =>
                        setCurrentCompany({ ...currentCompany, name: text })
                      }
                    />
                  </FormControl>
                  <FormControl
                    text={texts('FISCAL_CODE')}
                    className="inputText"
                    mandatory
                  >
                    <TextInput
                      value={currentCompany.idNumber}
                      disabled={disableFields.idNumber}
                      onChangeValue={(text) =>
                        setCurrentCompany({ ...currentCompany, idNumber: text })
                      }
                    />
                  </FormControl>
                </FormColumn>
                <FormColumn style={{ width: '100%' }}>
                  <FormControl text="Email" className="inputText" mandatory>
                    <TextInput
                      value={currentCompany.email}
                      disabled={disableFields.email}
                      onChangeValue={(text) => {
                        setCurrentCompany({ ...currentCompany, email: text });
                        setEmailRecipient(text);
                      }}
                    />
                  </FormControl>
                  <FormControl
                    text={texts('VAT_NUMBER')}
                    className="inputText"
                    mandatory
                  >
                    <TextInput
                      value={currentCompany.vat_number}
                      disabled={disableFields.vat_number}
                      onChangeValue={(text) =>
                        setCurrentCompany({
                          ...currentCompany,
                          vat_number: text,
                        })
                      }
                    />
                  </FormControl>
                </FormColumn>
              </StackView>
              <FormColumn style={{ width: '100%' }}>
                <FormControl
                  text={texts('WILL_SEND_CODE_TO_MOBILE_PHONE')}
                  mandatory
                >
                  <TextInput
                    value={currentCompany.cellphone}
                    onChangeValue={(text) =>
                      setCurrentCompany({ ...currentCompany, cellphone: text })
                    }
                  />
                </FormControl>
              </FormColumn>
            </StackView>
          )}
          {state === authCodes.SMS_SENT && (
            <React.Fragment>
              <StackView
                direction="horizontal"
                className="mt-20"
                style={{ alignItems: 'center !important' }}
              >
                <FormControl
                  style={{ width: '100% !important' }}
                  text={
                    texts('INSERT_RECEIVED_PIN') +
                    ' ' +
                    currentCompany.cellphone
                  }
                  mandatory
                >
                  <TextInput
                    value={pin}
                    onChangeValue={(text) => setPin(text)}
                  />
                </FormControl>
              </StackView>
              {isErrorVisibile && (
                <div className="mt-20 ml-15 mr-15">
                  <NotificationBanner
                    style={{ paddingRight: '10px' }}
                    type="error"
                    text={errorText}
                    onClose={() => setIsErrorVisibile(false)}
                  />
                </div>
              )}
            </React.Fragment>
          )}
          {state === authCodes.EMAIL_SENT && (
            <React.Fragment>
              <StackView
                direction="vertical"
                className="mt-20"
                style={{ alignItems: 'center !important' }}
              >
                <h3 style={{ margin: '20px' }}>
                  {texts('WE_SENT_YOU_AN_EMAIL') + emailRecipient + ','}
                  <br />
                  {texts('CLICK_ON_THE_CONFIRMATION_LINK')}
                </h3>
              </StackView>
            </React.Fragment>
          )}
          {state === authCodes.COMPANY_OK && (
            <React.Fragment>
              <StackView
                direction="vertical"
                className="mt-20"
                style={{ alignItems: 'center !important' }}
              >
                <h3>{texts('ACTIVATION_COMPLETED')}</h3>
              </StackView>
            </React.Fragment>
          )}
          {state === 'DATA' && (
            <FormButton
              disabled={isBtnDisabled}
              align="right"
              text={texts('CONFIRM_AND_SEND_CODE')}
              type="primary"
              onClick={() => sendSms()}
            />
          )}
          {state === authCodes.SMS_SENT && (
            <FormButton
              disabled={isBtnDisabled}
              align="right"
              text={texts('CONFIRM')}
              type="primary"
              onClick={() => createCompany()}
            />
          )}
          {state === authCodes.EMAIL_SENT && (
            <FormButton
              disabled={isBtnDisabled}
              align="right"
              text={texts('I_HAVE_CONFIRMED_EMAIL')}
              type="primary"
              onClick={() => createCompany()}
            />
          )}
          {state === authCodes.COMPANY_OK && (
            <FormButton
              align="right"
              text={texts('CLOSE')}
              type="primary"
              onClick={() => closeModal(true)}
            />
          )}
          {isWarningVisibile && (
            <div className="mt-20">
              <NotificationBanner
                type="warning"
                text={texts('MANDATORY_FIELDS_MESSAGE')}
                timeout={{
                  time: 6,
                  onTimeout: () => {
                    setIsWarningVisibile(false);
                  },
                }}
              />
            </div>
          )}
        </Form>
      </div>
    </Popup>
  );
};

export default AuthenticationModal;
