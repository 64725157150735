import React, { useEffect, useState } from 'react';
import {
  Page,
  PageHeader,
  Button,
  PopupDialog,
  NotificationBanner,
} from '@revisohq/react-components';
import '../App.css';
import MainSection from '../components/main/MainSection';
import Logo from '../components/logo/Logo';
import texts from '../utils/texts';
import { selectStatus, updateStatus } from '../store/initialStatusSlice';
import { useSelector, useDispatch } from 'react-redux';
import AuthenticationModal from '../components/authentication/AuthenticationModal';
import * as _api from '../api/authApi';
import { Spinner } from '../components/loader/loaders';
import { NewUserLink } from '../components/newUserSection/new-user-link';
import { CheckCircleIcon } from '@revisohq/react-components/icons';

export function HomePage() {
  const dispatch = useDispatch();

  const initialStatus = useSelector(selectStatus);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [registrationData, setRegistrationData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isActiveBtnDisabled, setIsActiveBtnDisabled] = useState(false);

  const [revisoEdition, setRevisoEdition] = useState(12);
  const [showActivationDialog, setShowActivationDialog] = useState(false);

  const [digitalEndpoint, setDigitalEndpoint] = useState(
    'https://app.teamsystemdigital.com/portale/'
  );

  const freeActivation = () => {
    setIsActiveBtnDisabled(true);
    setIsLoading(true);
    _api.GetRegistrationData().then(
      (res) => {
        if (res?.isUserRegistered) {
          setRegistrationData(res);
          setIsAuthModalOpen(true);
        } else {
          setRevisoEdition(res.revisoEdition);
          setShowActivationDialog(true);
        }
        setIsLoading(false);
        setIsActiveBtnDisabled(false);
      },
      (err) => {
        console.error(err);
        setIsLoading(false);
        setIsActiveBtnDisabled(false);
      }
    );
  };

  const closeAuthenticationModal = (refreshStatus) => {
    setIsAuthModalOpen(false);
    if (refreshStatus) {
      setIsLoading(true);
      _api.GetInitialStatus().then((s) => {
        setIsLoading(false);
        dispatch(updateStatus(s));
      });
    }
  };

  useEffect(() => {
    _api.getDigitalEndpoint().then((endpoint) => {
      if (endpoint) setDigitalEndpoint(endpoint);
    });
  }, []);

  return (
    <Page className="master">
      <PageHeader isSticky={true}>
        {isLoading && <Spinner />}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div>
            <h2 style={{ marginBottom: '0' }}>Invia dati a Bilancio in Cloud</h2>
            Da qui puoi prelevare i dati di bilancio da trasferire su Bilancio in Cloud per poi
            poter predisporre il bilancio XBRL,
            <br />
            la nota integrativa, il rendiconto finanziario e tutto quel che occorre per il deposito
            del bilancio. Per approfondimenti{' '}
            <a
              href="https://www.reviso.com/it/assistenza/articoli/il-bilancio-e-la-nota-integrativa/"
              target="_blank"
              rel="noopener noreferrer">
              leggi la nostra guida
            </a>
            .<br />
            {initialStatus.isAuthenticated && (
              <>
                Per creare il fascicolo del bilancio una volta inviati i dati a Bilancio in Cloud
                <b>
                  {' '}
                  <a href={digitalEndpoint} target="_blank" rel="noopener noreferrer">
                    accedi a TS Digital
                  </a>
                </b>{' '}
                e se non hai un utente per accedervi <NewUserLink />.
                <br />
              </>
            )}
          </div>
          <div>
            {!initialStatus.isAuthenticated && (
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: '35px' }}>
                <Logo />
              </div>
            )}
            {initialStatus.isAuthenticated && (
              <div class="tooltip">
                <div className="tooltip-text">{texts('SERVICE_ACTIVE')}</div>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <Logo />
                  <div className="tooltip-icon">
                    <CheckCircleIcon />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <MainSection />
        {!initialStatus.isAuthenticated && (
          <div style={{ marginTop: '10px' }}>
            <Button
              type="primary"
              text={texts('ACTIVATE_SERVICE')}
              onClick={() => freeActivation()}
              disabled={isActiveBtnDisabled}></Button>
          </div>
        )}
        {isAuthModalOpen && (
          <AuthenticationModal
            year={new Date().getFullYear()}
            company={registrationData?.company}
            isUserRegistered={registrationData?.isUserRegistered}
            closeModal={(refreshStatus) => closeAuthenticationModal(refreshStatus)}
          />
        )}
        {showActivationDialog && (
          <PopupDialog
            style={{ width: '500px', textAlign: 'right', top: '20%' }}
            onClose={() => setShowActivationDialog(false)}
            closeButtonText="Chiudi">
            <div style={{ textAlign: 'left' }}>
              <p>Gentile Cliente,</p>
              {revisoEdition === 13 ? (
                <p>
                  Per poter attivare il servizio Bilancio in Cloud, è necessario procedere
                  preliminarmente all’attivazione del servizio di Fatturazione Elettronica in Danea
                  Easyfatt, ed eseguire contestualmente la procedura di registrazione sulla
                  piattaforma TS Digital e l’accettazione del relativo contratto. Per sapere come
                  attivare il servizio di Fatturazione Elettronica in Danea Esyfatt, legga la guida
                  in linea:{' '}
                  <a
                    href="https://www.danea.it/software/easyfatt/help/#t=FatturaPA.htm"
                    target="_blank"
                    rel="noopener noreferrer">
                    Fatturazione elettronica
                  </a>
                  .
                </p>
              ) : (
                <p>
                  Per poter attivare il servizio Bilancio in Cloud, è necessario procedere
                  preliminarmente all’attivazione del servizio di Fatturazione Elettronica ed
                  eseguire contestualmente la procedura di registrazione sulla piattaforma TS
                  Digital con l’accettazione del relativo contratto. Per sapere come attivare il
                  servizio di Fatturazione Elettronica incluso nel prezzo di Contabilità in Cloud,
                  legga la guida in linea:{' '}
                  <a
                    href="https://www.reviso.com/it/assistenza/articoli/attivazione-servizio-di-fatturazione-elettronica/"
                    target="_blank"
                    rel="noopener noreferrer">
                    Attivazione del servizio di fatturazione elettronica
                  </a>
                  .
                </p>
              )}
            </div>
          </PopupDialog>
        )}
      </PageHeader>
    </Page>
  );
}
