export function DeepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function getFormattedDate(date: String) {
  if (!date) return '';
  const year = date.substring(0, 4);
  const month = date.substring(5, 7);
  const day = date.substring(8, 10);
  return day + '/' + month + '/' + year;
}

export function getLocalDateTimeString(dateTime: String, locale: String = 'it-IT') {
  if (!dateTime || dateTime.includes('0001-01-01')) return '';

  const year = dateTime.substring(0, 4),
    month = dateTime.substring(5, 7),
    day = dateTime.substring(8, 10),
    hours = dateTime.substring(11, 13),
    minutes = dateTime.substring(14, 16);

  const parsedDateTime = new Date(Date.UTC(year, month - 1, day, hours, minutes));

  const dateOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
    hourOptions = {
      hour: '2-digit',
      minute: '2-digit',
    };

  const formatted = `${parsedDateTime.toLocaleDateString(
    locale,
    dateOptions
  )}, ${parsedDateTime.toLocaleTimeString(locale, hourOptions)}`;

  return formatted;
}

export function StringIsNullOrEmpty(str) {
  return !str || 0 === str.toString().trim().length;
}

export function StringIsNotNullOrEmpty(str) {
  return !(!str || 0 === str.toString().trim().length);
}

export const isNullOrWhitespace = (input) => {
  if (typeof input === 'undefined' || input == null) return true;
  return input.replace(/\s/g, '').length < 1;
};

export const validatePassword = (password) => {
  var re = {
    capital: /[A-Z]/,
    digit: /[0-9]/,
    symbol: /[@#!£$%&_:;ç°§*?^]/,
    full: /^[A-Za-z0-9]{8,40}$/,
  };
  return re.capital.test(password) && re.digit.test(password) && re.full.test(password);
};

export const reportTableConfig = {
  creationDate: { width: '10%' },
  sendDate: { width: '7%' },
  status: { width: '12%' },
  name: { width: '29%' },
  type: { width: '7%' },
  periodStart: { width: '7%' },
  periodEnd: { width: '7%' },
  includeDraftEntries: { width: '15%' },
  downloadButton: { width: '2%' },
  updateStatusButton: { width: '2%' },
  deleteButton: { width: '2%' },
};

export const balanceTypes = [
  {
    id: 0,
    text: 'Ordinario',
  },
  {
    id: 1,
    text: 'Abbreviato',
  },
  {
    id: 2,
    text: 'Micro',
  },
];
