import React from "react"

export function UpgradeMessage() {
    return <div style={{ width: "100%", "text-align": "center", padding: "30px" }}>
        <div>
            Questa funzionalità non è inclusa nel piano di abbonamento corrente.
        </div>
        <a href="https://app.reviso.com/#!/settings/subscription/pricing-page" style={{ color: "#1D8CD3" }} target="_blank" rel="noopener noreferrer">
            Sottoscrivi il piano di abbonamento successivo.
        </a>
    </div>
}