import React, { useEffect, useState } from 'react';
import {
  List,
  ListColumns,
  ListColumn,
  Button,
  StackView,
  NotificationBanner,
} from '@revisohq/react-components';
import ReportTableItem from './ReportTableItem';
import texts from '../../utils/texts';
import NewReportSection from './NewReportSection';
import { useDispatch, useSelector } from 'react-redux';
import { selectStatus, updateStatus } from '../../store/initialStatusSlice';
import { reportTableConfig as columnConfig } from '../../utils/common';
import { Spinner } from '../loader/loaders';
import * as _api from '../../api/balanceApi';
const ReportTable = () => {
  const dispatch = useDispatch();

  const initialStatus = useSelector(selectStatus);

  const [reports, setReports] = useState(initialStatus?.reports ?? []);
  const [isNewReportSectionVisible, setIsNewReportSectionVisible] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isExportButtonDisabled, setIsExportButtonDisabled] = useState(false);
  const [isNotificationBannerVisible, setIsNotificationBannerVisible] = useState(false);
  const [notificationBannerMessage, setNotificationBannerMessage] = useState();
  const [notificationBannerType, setNotificationBannerType] = useState();

  useEffect(() => {
    setReports(initialStatus?.reports ?? []);
  }, [initialStatus]);

  const onItemUpdated = (report) => {
    let clonedReports = JSON.parse(JSON.stringify(reports));
    let currentClonedItem = clonedReports.find((o) => {
      return o.id === report.id;
    });
    currentClonedItem.status = report.status;
    currentClonedItem.sendDate = report.sendDate;
    setReports(clonedReports);
  };

  const onListRefresh = async (report) => {
    setIsLoading(true);
    var reports = await _api.getReportsList();
    dispatch(updateStatus({ ...initialStatus, reports }))
    setIsLoading(false);
  };

  const handleOnCloseModal = () => {
    setIsNewReportSectionVisible(false);
  };

  const handleOnLoadingChanged = (loading) => {
    if (loading) {
      setIsNewReportSectionVisible(false);
    }
    setIsLoading(loading);
  };

  const handleOnReportCreated = (isSuccess) => {
    if (isSuccess) {
      setNotificationBannerMessage(texts('REPORT_SUCCESSFULLY_CREATED'));
      setNotificationBannerType('success');
    } else {
      setNotificationBannerMessage(texts('CREATION_REPORT_ERROR'));
      setNotificationBannerType('error');
    }
    setIsNotificationBannerVisible(true);
  };

  return (
    <React.Fragment>
      {isLoading && <Spinner />}
      <StackView direction="horizontal" style={{ alignItems: 'center' }}>
        <Button
          type="primary"
          text={texts('EXPORT_DATA')}
          onClick={() => {
            setIsNewReportSectionVisible(true);
          }}
          disabled={isExportButtonDisabled}
        ></Button>
      </StackView>
      {reports?.length > 0 ? (
        <List className="mt-20">
          <ListColumns>
            <ListColumn {...columnConfig.sendDate}>
              {texts('SENDING_DATE')}
            </ListColumn>
            <ListColumn {...columnConfig.creationDate}>
              {texts('CREATION_DATE')}
            </ListColumn>
            <ListColumn {...columnConfig.status}>
              {texts('SENDING_STATE')}
            </ListColumn>
            <ListColumn {...columnConfig.type}>
              {texts('BALANCE_TYPE')}
            </ListColumn>
            <ListColumn {...columnConfig.name}>
              {texts('DESCRIPTION')}
            </ListColumn>
            <ListColumn className="display-b" {...columnConfig.periodStart}>
              {texts('PERIOD_START')}
            </ListColumn>
            <ListColumn className="display-b" {...columnConfig.periodEnd}>
              {texts('PERIOD_END')}
            </ListColumn>
            <ListColumn
              className="display-b"
              {...columnConfig.includeDraftEntries}
            >
              {texts('INCLUDE_DRAFT_ENTRIES')}
            </ListColumn>
            <ListColumn
              className="ta-r display-b"
              {...columnConfig.updateStatusButton}
            ></ListColumn>
            <ListColumn
              className="ta-r display-b"
              {...columnConfig.downloadButton}
            ></ListColumn>
            <ListColumn
              className="ta-r display-b"
              {...columnConfig.deleteButton}
            ></ListColumn>
          </ListColumns>

          {reports?.map((obj, i) => {
            return (
              <ReportTableItem
                item={obj}
                onItemUpdated={onItemUpdated}
                onListRefresh={onListRefresh}
              />
            );
          })}
        </List>
      ) : (
        <div className="mt-20">{texts('NO_REPORTS_FOUND')}</div>
      )}
      {isNewReportSectionVisible && (
        <NewReportSection
          onLoadingChanged={(loading) => {
            handleOnLoadingChanged(loading);
          }}
          onCloseModal={handleOnCloseModal}
          onReportCreated={handleOnReportCreated}
        />
      )}
      <div className="mt-20">
        {isNotificationBannerVisible && (
          <NotificationBanner
            type={notificationBannerType}
            text={notificationBannerMessage}
            timeout={{
              time: 3,
              onTimeout: () => setIsNotificationBannerVisible(false),
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default ReportTable;
